.team-container{
  margin-top:60px;
}

h1{  
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 50px;
}

h6 {
  text-align: justify;
  font-weight: 400;
  margin-left: 15px;
  margin-right: 15px;
}

.main-image img {
  object-fit: cover;
  height: 400px;
  width:300px;
  align-items: center;
  align-self: center;
  margin: 0 auto;
}

.main-image{
  margin-bottom:100px;
  background-color: #F1F1F1;
  width:300px;
  margin: 0 auto;
}


.main-image-text{
  margin: 0 auto;
  align-items: center;
  align-self: center;
  padding: 10px;
}

.main-image h3{
  margin: 0;
  font-weight: 600;
  font-size: 20px;
}

.main-image h6{
  font-weight: 400;
  font-size: 14px;
  text-align: center;
}

.main-image p{
  margin: 0;
  font-weight: 400;
  font-size: 15px;
}

.abogadas img{
  object-fit:cover;
  height:320px;
  width:255px;
}

 .abogada1{
   background-color: #f1f1f1;
   width: 255px;
   margin: 50px auto;
 }
 .abogada2{
   background-color: #f1f1f1;
   width: 255px;
   margin: 50px auto;
 }
 .abogada3{
   background-color: #f1f1f1;
   width: 255px;
   margin: 50px auto;
 }

 .abogada1 h3{
  margin: 0;
  font-weight: 600;
  font-size: 16px;
}
  .abogada1 p{
  margin: 0;
  font-weight: 400;
  font-size: 14px;
}

.abogada1-text{
  padding: 10px;
}
.abogada2-text{
  padding: 10px;
}
.abogada3-text{
  padding: 10px;
}

 .abogada2 h3{
  margin: 0;
  font-weight: 600;
  font-size: 16px;
}
  .abogada2 p{
  margin: 0;
  font-weight: 400;
  font-size: 14px;
}


 .abogada3 h3{
  margin: 0;
  font-weight: 600;
  font-size: 16px;
}
  .abogada3 p{
  margin: 0;
  font-weight: 400;
  font-size: 14px;
}


@media only screen and (min-width: 610px) {

  .abogadas{
    display: flex;
    flex-direction: row;
    max-width: 800px;
    margin: 0 auto;
  }
  .abogadas img{
    object-fit:cover;
    height:200px;
    width:160px;
  }

  .abogada1{
    background-color: #f1f1f1;
    width: 160px;
    margin: 50px auto;
  }
  .abogada2{
    background-color: #f1f1f1;
    width: 160px;
    margin: 50px auto;
  }
  .abogada3{
    background-color: #f1f1f1;
    width: 160px;
    margin: 50px auto;
  }

  .abogada1 h3{
    margin: 0;
    font-weight: 600;
    font-size: 14px;
  }
    .abogada1 p{
    margin: 0;
    font-weight: 400;
    font-size: 14px;
  }
  .abogada2 h3{
    margin: 0;
    font-weight: 600;
    font-size: 14px;
  }
    .abogada2 p{
    margin: 0;
    font-weight: 400;
    font-size: 14px;
  }
  .abogada3 h3{
    margin: 0;
    font-weight: 600;
    font-size: 14px;
  }
    .abogada3 p{
    margin: 0;
    font-weight: 400;
    font-size: 14px;
  }
  }

  @media only screen and (min-width: 700px) {

    .abogadas img{
      object-fit:cover;
      height:230px;
      width:180px;
    }
  
    .abogada1{
      background-color: #f1f1f1;
      width: 180px;
      margin: 50px auto;
    }
    .abogada2{
      background-color: #f1f1f1;
      width: 180px;
      margin: 50px auto;
    }
    .abogada3{
      background-color: #f1f1f1;
      width: 180px;
      margin: 50px auto;
    }
    }


    @media only screen and (min-width: 800px) {

      .abogadas img{
        object-fit:cover;
        height:250px;
        width:200px;
      }
    
      .abogada1{
        background-color: #f1f1f1;
        width: 200px;
        margin: 50px auto;
      }
      .abogada2{
        background-color: #f1f1f1;
        width: 200px;
        margin: 50px auto;
      }
      .abogada3{
        background-color: #f1f1f1;
        width: 200px;
        margin: 50px auto;
      }
      }

      @media only screen and (min-width: 900px) {

        .abogadas img{
          object-fit:cover;
          height:270px;
          width:220px;
        }
      
        .abogada1{
          background-color: #f1f1f1;
          width: 220px;
          margin: 50px auto;
        }
      
        .abogada2{
          background-color: #f1f1f1;
          width: 220px;
          margin: 50px auto;
        }
      
        .abogada3{
          background-color: #f1f1f1;
          width: 220px;
          margin: 50px auto;
        }

        .abogada h3{
          margin: 0;
          font-weight: 600;
          font-size: 18px;
        }
          .abogada p{
          margin: 0;
          font-weight: 400;
          font-size: 15px;
        }

      }


      @media only screen and (min-width: 1024px) {

        .abogadas img{
          object-fit:cover;
          height:270px;
          width:220px;
        }
      
        .abogada1{
          background-color: #f1f1f1;
          width: 220px;
          margin: 50px auto;
        }
        .abogada2{
          background-color: #f1f1f1;
          width: 220px;
          margin: 50px auto;
        }
        .abogada3{
          background-color: #f1f1f1;
          width: 220px;
          margin: 50px auto;
        }

        .abogada h3{
          margin: 0;
          font-weight: 600;
          font-size: 18px;
        }
          .abogada p{
          margin: 0;
          font-weight: 400;
          font-size: 15px;
        }
      }


.abogada1:hover{
  color:white;
  background-color: #2965B8;
}
.abogada2:hover{
  color:white;
  background-color: #2965B8;
}
.abogada3:hover{
  color:white;
  background-color: #2965B8;
}

.main-image:hover{
  color:white;
  background-color: #2965B8;
}