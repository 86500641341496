.button {
  width: 121px;
  border: 1px solid #000;
  font-weight: 600;
  font-size: 10px;
  background-color: white;
  border-radius: 3px;
  border-width: 1px;
  padding:3px;
  padding-top: 5px;
  padding-bottom: 5px;
  list-style-type: none;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 5px;
}

.buttons div {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.buttons {
  display: flex;
  margin: auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  max-width: 1200px;
  margin-top: 15px;
  margin-bottom: 25px;
}

.buttons a:link,
.buttons a:visited {
  color: #000000;
  text-decoration: none;
}

.buttons a:hover {
  background-color:#2965B8;
  border: 1px solid #2965B8;
  color:white;
}

.selected {
  background-color:#2965B8 !important;
  border: 1px solid #2965B8 !important;
  color:white !important;
}

.logo {
  width: 158px;
}


@media only screen and (min-width: 450px) {
  .button {
    width: 140px;
    font-size: 12px;
  }
}


@media only screen and (min-width: 515px) {
  .button {
    width: 160px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 570px) {
  .button {
    width: 180px;
    font-size: 16px;
  }
}


@media only screen and (min-width: 635px) {
  .button {
    width: 190px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 660px) {
  .button {
    width: 210px;
    font-size: 16px;
  }
}
  @media only screen and (min-width: 725px) {
    .button {
      width: 200px;
      font-size: 16px;
    }

      div.logo{
      width: 340px;
    }
}

  