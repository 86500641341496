.container {
  margin-top: 50px;
  background-color: #F1F1F1;
  padding-bottom: 30px;
}
.logo {
  width: 200px;
  margin-top: 30px;
  margin-bottom: 5px;
}

hr {
  width:250px;
}

.component p {
  margin: 10px auto;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.component img {
  margin-top:20px;
}