.App {
  text-align: center;
  margin-top:20px;
  max-width: 100%;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

button:hover {
  cursor: pointer;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 19px;
}