.type {
  position: absolute;
  color: white;
  top:350px
}

.type h1{
  text-align: left;
  margin:10px;  
  font-size: 24px;
  font-weight: 600;
}

.contact-button {
  padding: 8px;
  display: flex;
  margin: 10px;
  background-color: #2965B8;
  color: white;
  border-radius: 3px;
  border: none;
  font-weight: 600;
  font-size: 10px;
  text-align: center;
  min-width: 175px;
}


.type p{
  text-align: left;
  margin:10px;  
  font-size: 10px;
  font-weight: 500;
  line-height: 20px;
  margin-top:15px;
  margin-bottom:15px;
}

.contact-button-send:hover{
  background-color: #7395a2;
}

@media only screen and (min-width: 320px) {
  
  .cover1 {
    position: relative;
    padding-top:10px;
    mask-size: 12px;
    object-fit: cover;
    height: 440px;
  }

  .type {
    position: absolute;
    color: white;
    top:290px
  }

  .type h1{
    text-align: left;
    margin:10px;  
    font-size: 24px;
    font-weight: 600;
  }

  .type p{
    text-align: left;
    margin:10px;  
    font-size: 10px;
    font-weight: 500;
    line-height: 20px;
    margin-top:15px;
    margin-bottom:15px;
  }

  .contact-button {
    padding: 8px;
    display: flex;
    margin: 10px;
    background-color: #2965B8;
    color: white;
    border-radius: 3px;
    border: none;
    font-weight: 600;
    font-size: 10px;
  }
}

@media only screen and (min-width: 320px) {
  .type {
    position: absolute;
    color: white;
    top:305px
  }
}


@media only screen and (min-width: 400px) {
  .type {
    position: absolute;
    color: white;
    top:320px
  }
}

@media only screen and (min-width: 500px) {
  .type {
    position: absolute;
    color: white;
    top:310px;
    margin-left: 30px;
    margin-right: 30px;
  }

  .type p {
    font-size: 12px;
  }

  .type h1{
    text-align: left;
    margin:10px;  
    font-size: 28px;
    font-weight: 600;
  }
}

@media only screen and (min-width: 610px) {
  .type {
    position: absolute;
    color: white;
    top:320px
  }
}

@media only screen and (min-width: 720px) {
  .type {
    position: absolute;
    color: white;
    top:300px
  }
}


@media only screen and (min-width: 768px) {
  .type {
    position: absolute;
    color: white;
    top:300px;
  }
  .type h1{
      text-align: left;
      margin:10px;  
      font-size: 40px;
      font-weight: 600;
    }
  
    .type p{
      text-align: left;
      margin:10px;  
      font-size: 15px;
      font-weight: 500;
      line-height: 25px;
      margin-top:15px;
      margin-bottom:15px;
    }

    .cover1 {
      position: relative;
      padding-top:10px;
      mask-size: 12px;
      object-fit: cover;
      height: 600px;
    }
    .contact-button {
      padding: 8px;
      display: flex;
      margin: 10px;
      background-color: #2965B8;
      color: white;
      border-radius: 3px;
      border: none;
      font-weight: 600;
      font-size: 15px;
    }

  }
  
  @media only screen and (min-width: 900px) {
    .type {
      position: absolute;
      color: white;
      top:320px;
    }
    .type h1{
        text-align: left;
        margin:10px;  
        font-size: 40px;
        font-weight: 600;
      }
    
      .type p{
        text-align: left;
        margin:10px;  
        font-size: 15px;
        font-weight: 500;
        line-height: 25px;
        margin-top:25px;
        margin-bottom:25px;
      }
  
      .cover1 {
        position: relative;
        padding-top:10px;
        mask-size: 12px;
        object-fit: cover;
        height: 600px;
      }

      .contact-button {
        padding: 8px;
        display: flex;
        margin: 10px;
        background-color: #2965B8;
        color: white;
        border-radius: 3px;
        border: none;
        font-weight: 600;
        font-size: 15px;
      }
    }


    @media only screen and (min-width: 1100px) {
      .type {
        position: absolute;
        color: white;
        top:300px;
        justify-content: center;
      }
      .type h1{
          text-align: left;
          margin:10px;  
          font-size: 45px;
          font-weight: 600;
        }
      
        .type p{
          text-align: left;
          margin:10px;  
          font-size: 15px;
          font-weight: 500;
          line-height: 30px;
          margin-top:25px;
          margin-bottom:25px;
        }
    
        .cover1 {
          position: relative;
          padding-top:10px;
          mask-size: 12px;
          object-fit: cover;
          height: 600px;
        }

        .contact-button {
          padding: 8px;
          display: flex;
          margin: 10px;
          background-color: #2965B8;
          color: white;
          border-radius: 3px;
          border: none;
          font-weight: 600;
          font-size: 15px;
        }
    }

    @media only screen and (min-width: 1250px) {
      .cover1 {
        position: relative;
        padding-top:10px;
        mask-size: 12px;
        object-fit: cover;
        height: 800px;
      }

      .type {
        position: absolute;
        color: white;
        top:380px;
        justify-content: center;
      }
    }

    /* @media only screen and (min-width: 1700px) {
      .cover1 {
        position: relative;
        padding-top:10px;
        mask-size: 12px;
        object-fit: cover;
        height: 1000px;
      }

      .type {
        position: absolute;
        color: white;
        top:480px;
        margin:0px 80px 0px 80px;
        justify-content: center;
      }
    } */
  

    button.contact-button > p {
      margin: 0;
      text-align: center;
      width: 100%;
      font-family: 'Montserat', sans-serif;
    }
