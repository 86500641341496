.wrapper {
  display: flex;
  flex-direction: column;
}

.cover {
  display: flex;

}

.typeh {
  color: white;
  background: url('/portada.png');
  background-size: cover;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 15px;
}

.typeh h1{
  text-align: justify;
  margin:10px;  
  font-size: 24px;
  font-weight: 600;
}

.contact-button {
  padding: 8px;
  display: flex;
  margin: 10px;
  background-color: #2965B8;
  color: white;
  border-radius: 3px;
  border: none;
  font-weight: 600;
  font-size: 10px;
  width: 150px;
}

.contact-button:hover{
  background-color: #7395a2;
}


.typeh p {
  text-align: justify;
  text-justify: inter-word;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  max-width: 750px;
  padding-left: 10px;
}


.contact-title {
  margin-top: 40px;
  font-weight: 600;
  font-size: 24px;
}

.contact-form{
  display:flex;
  flex-direction:  column;
  text-align: left;
  margin: 0 auto;
  max-width: 500px;
  
}
.insert {
  font-size: 12px;
  text-align: left;
}

.text-putter textarea{
  height:25px;
  margin-bottom:10px;
  margin-top: 5px;
  width: 100%;
}

.text-putter input{
  height:25px;
  margin-bottom:10px;
  margin-top: 5px;
  width: 100%;
}

/* .text-putter input:hover{
  background-color: #F1F1F1;
}

.text-putter textarea:hover{
  background-color: #F1F1F1;
}


.text-putter-message input:hover{
  background-color: #F1F1F1;
} */

.text-putter-message input{
  height:100px;
  margin-bottom:10px;
  margin-top: 5px;
  width: 100%;
}

.contact-button-send {
  padding: 8px;
  text-align: center;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top:50px;
  background-color: #2965B8;
  color: white;
  border-radius: 3px;
  border: none;
  font-weight: 600;
  font-size: 16px;
}

.contact-button-send:hover{
  background-color: #7395a2;
}

.label-input {
  margin:auto;
  margin-top: 20px;
  width:70%;
}

.banner {
  padding: 5px 20px 15px 20px;
  max-width: 900px;
  font-size: 16px;
  margin: 0 auto;
}

@media only screen and (min-width: 320px) {
  
  .cover1 {
    position: relative;
    padding-top:10px;
    mask-size: 12px;
    object-fit: cover;
    height: 440px;
  }

  .typeh h1{
    text-align: justify;
    margin:10px;  
    font-size: 24px;
    font-weight: 600;
  }

  .typeh p{
    text-align: justify;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .contact-button {
    padding: 8px;
    display: flex;
    margin: 10px;
    background-color: #2965B8;
    color: white;
    border-radius: 3px;
    border: none;
    font-weight: 600;
    font-size: 10px;
    text-decoration: none;
  }


  

}

@media only screen and (min-width: 500px) {
  .typeh p {
    font-size: 14px;
  }

  .typeh h1{
    text-align: justify;
    margin:10px;  
    font-size: 28px;
    font-weight: 600;
  }
}

@media only screen and (min-width: 768px) {
  .typeh {
    min-height: 630px;
    padding-left: 40px;
  }

  .typeh h1{
      text-align: justify;
      margin:10px;  
      font-size: 40px;
      font-weight: 600;
    }
  
    .typeh p{
      text-align: justify; 
      font-size: 14px;
      font-weight: 500;
      line-height: 25px;
    }

    .cover1 {
      position: relative;
      padding-top:10px;
      mask-size: 12px;
      object-fit: cover;
      height: 600px;
    }
    .contact-button {
      padding: 8px;
      display: flex;
      margin: 10px;
      background-color: #2965B8;
      color: white;
      border-radius: 3px;
      border: none;
      font-weight: 600;
      font-size: 15px;
    }

  }
  
  @media only screen and (min-width: 900px) {
    .typeh h1{
        text-align: justify;
        margin:10px;  
        font-size: 40px;
        font-weight: 600;
      }
    
      .typeh p{
        text-align: justify;
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
      }
  
      .cover1 {
        position: relative;
        padding-top:10px;
        mask-size: 12px;
        object-fit: cover;
        height: 600px;
      }

      .contact-button {
        padding: 8px;
        display: flex;
        margin: 10px;
        background-color: #2965B8;
        color: white;
        border-radius: 3px;
        border: none;
        font-weight: 600;
        font-size: 15px;
      }
    }

    @media only screen and (min-width: 992px) {
      .typeh {
        min-height: 790px;
        padding-left: 80px;
      }
    }

    @media only screen and (min-width: 1100px) {
      .typeh h1{
          text-align: justify;
          margin:10px;  
          font-size: 45px;
          font-weight: 600;
        }
      
        .typeh p{
          text-align: justify; 
          font-size: 16px;
          font-weight: 500;
          line-height: 30px;
        }
    
        .cover1 {
          position: relative;
          padding-top:10px;
          mask-size: 12px;
          object-fit: cover;
          height: 600px;
        }

        .contact-button {
          padding: 8px;
          display: flex;
          margin: 10px;
          background-color: #2965B8;
          color: white;
          border-radius: 3px;
          border: none;
          font-weight: 600;
          font-size: 15px;
        }
    }

    @media only screen and (min-width: 1250px) {
      .cover1 {
        position: relative;
        padding-top:10px;
        mask-size: 12px;
        object-fit: cover;
        height: 800px;
      }
    }

    @media only screen and (min-width: 1700px) {
      .cover1 {
        position: relative;
        padding-top:10px;
        mask-size: 12px;
        object-fit: cover;
        height: 1000px;
      }
    }
  


    a.contact-button > p {
      margin: 0;
      text-align: center;
      width: 100%;
    }